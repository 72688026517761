:root{
  --font-base: "DM Sans", sans-serif;
}
body{
  background-image: url("./assets/images/pictures/background.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    font-family: var(--font-base);
  }