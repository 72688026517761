.app__flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.app__navbar-links{ 
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 7;

    list-style: none;

    .disabled {
        cursor: default;

        div{
            background-color: #4f342e;
            transition: none;
        }
    }
    

    li{
        margin: 0 1rem;
        cursor: pointer;

        flex-direction: column;

        

        div{
            width: 5px;
            height: 5px;
            background: transparent;
            border-radius: 50%;

            margin-bottom: 5px;
        }

        a{
            color: #0b1b4e;
            text-decoration: none;
            flex-direction: column;

            text-transform: uppercase;
            font-weight: 500;

            transition: all 0.3s ease-in-out;

            

            &:hover {
                color: #4e0b0b;
            }

        }

        button{
            background: none;
            border: none;

            color: #4f342e91;
            text-decoration: none;
            flex-direction: column;
            cursor: pointer;

            text-transform: uppercase;
            font-weight: 600;

            transition: all 0.3s ease-in-out;

            &:hover {
                color: #4f342e;
            }

            &:disabled{
                color: #4f342e;
            }
        }

        &:hover{
            div{
                background-color: #4f342e91;
            }
        }

        
    }

    @media screen and (max-width: 900px){
        li{
            margin: 0;
            div{
                margin: 0;
            }
        }
    }
}



.right{
    justify-content: right;
    flex-grow: 1;
}

.switch{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-grow: 1;

    >*{
        text-align: center;
    }

    &>*{
        margin: 0 2px;
    }

    p{
        padding: 5px 0;
        margin-right: 5px;
        text-align: center;
    }

    >div{
        @media screen and (min-width: 2000px) {
            margin-top: 12px;
        }
    }

    @media screen and (max-width: 2000px) {
        height: 21px;
    }
}

.app__navbar-menu{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);

    svg{
        width: 70%;
        height: 70%;
        color: var(--white-color);
    }

    div{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;

        padding: 1rem;

        width: 80%;
        height: 100vh;

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;

        background-color: var(--white-color);
        background-size: cover;
        background-repeat: repeat;

        box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

        svg{
            width: 35px;
            height: 35px;
            color: var(--secondary-color);
            margin: 0.5rem 1rem;
        }

        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            
            li {
                margin: 1rem;

                a{
                    color: var(--gray-color);
                    text-decoration: none;
                    font-size: 1rem;
                    text-transform: uppercase;

                    transition: all 0.3s ease-in-out;

                    &:hover {
                        color: var(--secondary-color);
                    }

                }
            }
        }

        @media screen and (min-width: 900px) {
            //display: none;
        }
    }
    
    @media screen and (min-width: 900px) {
        //display: none;
    }
}